import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootStore } from "../../../redux/Store";
import { UserViewModel } from "../../../presenter/user/viewModel/UserViewModel";

import ErrorMessage from "../../../components/ErrorMessage";
import { GetUsers } from "../../../redux/userList/UserListActions";
import { SnackClose } from "../../../redux/base/BaseActions";
import UsersTable from "../../../components/table/UserTable";
import Snackbar from "../../../components/Snackbar";
import Loader from "../../../components/Loader";
import { GetUtilities } from "../../../redux/utilityList/UtilityListActions";

export default function Users() {
  const { userList, error, loading } = useSelector(
    (state: RootStore) => state.userListReducer
  );
  const { utilityList } = useSelector(
    (state: RootStore) => state.utilityListReducer
  );

  const utilityLookup = utilityList
  .map((u) => u.shortName)
  .reduce((ut, cur) => ({ ...ut, [cur]: cur }), {});

  const { snackOpen, snackText } = useSelector(
    (state: RootStore) => state.baseReducer
  );

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(GetUsers());
    dispatch(GetUtilities());
  }, [dispatch]);

  const handleSnackBarClose = () => {
    dispatch(SnackClose())
  };

  const handleEdit = (event: any, user: UserViewModel | UserViewModel[]) => {
    if (!Array.isArray(user)) {
      const id = (user as UserViewModel).id
      history.push({ pathname: id });
    }
  };

  const handleDelete = (
    event: any,
    user: UserViewModel | UserViewModel[]
  ) => { };

  const errorView = <ErrorMessage message={error} />;
  const loadingView = <Loader />;
  const viewToRender = (<div>
    <UsersTable
      userList={userList}
      utilityLookup={utilityLookup}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
    />
    <Snackbar open={snackOpen}
      color={'success'}
      close={true}
      closeNotification={handleSnackBarClose}
      message={snackText}
      autoHideDuration={3000}
      place={'br'} />
  </div>
  );

  if (!error && !loading) {
    return viewToRender;
  } else if (loading) {
    return loadingView;
  } else {
    return errorView;
  }
}
