import UserList from "../../../domain/modules/user/valueObject/UserList";
import { IGetUsersOutput } from "../../../domain/modules/user/useCase/GetUsersUseCase";
import { GET_USERS_SUCCESS } from "../../redux/userList/UserListActionTypes";
import ReduxPresenter from "../ReduxPresenter";
import UserErrors from "./UserErrors";
import UserPresentation from "./presentation/UserPresentation";
import { IReduxDispatch } from "../IReduxDispatch";

export default class GetUsersPresenter extends ReduxPresenter implements IGetUsersOutput {

    constructor(dispatch: IReduxDispatch, private presentation: UserPresentation) {
        super(dispatch, new UserErrors().processor);
    }

    displaySuccessResponse = (userList: UserList): void => {
        const userViewModelList = userList.users.map(userData => this.presentation.map(userData))

        this.dispatch({
            type: GET_USERS_SUCCESS,
            payload: { userList: userViewModelList }
        })
    }

}