export const ErrorCodes = {
    SERVER_ERROR: "Internal Server Error",
    INVALID_CREDENTIALS: "Invalid credentials, please try again.",
    GET_UTILITY_LIST_FAIL: "An error occurred while trying to fetch utilities. Please reload.",
    GET_UTILITY_FAIL: "An error occurred while trying to fetch utility. Please reload.",
    GET_USERS_FAIL: "An error occurred while trying to fetch users. Please reload.",
    EDIT_USER_FAIL: "An error occurred while trying to update user. Please reload and try again.",
    EDIT_UTILITY_FAIL: "An error occurred while trying to update utility. Please reload and try again.",
    UTILITY_GET_LOGOS_FAIL: "An error occurred while trying to fetch logos. Please reload.",
    GET_STANDARD_PROFILES_FAIL: "An error occurred while trying to fetch standard profiles. Please reload.",
    UPDATE_STANDARD_PROFILE_FAILS: "An error occurred while trying to update standard profile. Please reload and try again.",
    GET_MAPPINGS_FAIL: "An error occurred while trying to fetch mappings. Please reload.",
    UPDATE_MAPPING_FAIL: "An error occurred while trying to update mapping. Please reload and try again.",
    ADD_MAPPING_FAIL: "An error occurred while trying to add mapping. Please reload and try again.",
    DELETE_MAPPING_FAIL: "An error occurred while trying to delete mapping. Please reload and try again.",
    RECALCULATE_STANDARD_PROFILES_FAIL: "An error occurred while trying to recalculate standard profiles for users. Please reload and try again."
}