import React from "react";

import { Breadcrumbs } from "@material-ui/core";
import { useLocation } from "react-router";
import BreadcrumbItem from "./BreadcrumbItem";

function makeUrlFromPathList(paths: string[], endIndex: number) {
  let result = "/app/";
  paths.forEach((path, index) => {
    if (index > endIndex) return;
    if (path.indexOf(" ") !== -1) {
      path = path.replace(" ", "-");
    }
    if (path.indexOf("profiles") !== -1 || path.indexOf("mappings") !== -1)
      result += `standard-profiles/${path}`;
    else result += `${path}/`;
  });
  return result;
}

function makeBreadcrumbsObjects(pathname: string): MenuItemObject[] {
  const pathList = pathname
    .replace("-", " ")
    .replace("app", "")
    .replace("standard profiles", "")
    .split("/")
    .filter((p) => p.length > 0);
  return pathList.map((p, index) => ({
    title: p,
    url: makeUrlFromPathList(pathList, index),
  }));
}

interface MenuItemObject {
  title: string;
  url: string;
}

export default function AppBreadcrumbs() {
  const { pathname } = useLocation();
  const [paths, setPaths] = React.useState<MenuItemObject[]>([]);

  React.useEffect(() => {
    const list = makeBreadcrumbsObjects(pathname);
    setPaths(list.length > 1 ? list : []);
  }, [setPaths, pathname]);

  return paths.length > 0 ? (
      <Breadcrumbs aria-label="breadcrumb">
        {paths.map((pObject, index) => (
          <BreadcrumbItem
            key={index}
            isLast={index === paths.length - 1}
            text={pObject.title}
            url={pObject.url}
          />
        ))}
      </Breadcrumbs>
  ) : null;
}
