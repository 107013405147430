import Utility from "../../../../domain/modules/utility/entity/Utility";
import UtilityList from "../../../../domain/modules/utility/valueObject/UtilityList";
import IMapper from "../../../../infrastructure/IMapper";
import { UtilityViewModel } from "../viewmodel/UtilityViewModel";

export default class UtilityPresentation implements IMapper<Utility, UtilityViewModel> {

    present(utilityList: UtilityList): UtilityViewModel[] {
        const utilityViewModelList = utilityList.utilities.map(util => {
            return this.map(util)
        });

        return utilityViewModelList;
    }

    map(input: Utility): UtilityViewModel {
        const { id, name, shortName, description, logos, contacts, socialNetworks } = input;

        return {
            id: id.getId().toString(),
            name: name.value,
            shortName: shortName.value,
            description: UtilityPresentation.merge("", description?.description),
            logoLight: UtilityPresentation.merge("", logos?.logoLight),
            logoDark: UtilityPresentation.merge("", logos?.logoDark),
            address: UtilityPresentation.merge("", contacts?.address),
            email: UtilityPresentation.merge("", contacts?.email),
            phone: UtilityPresentation.merge("", contacts?.phone),
            website: UtilityPresentation.merge("", contacts?.website),
            facebook: UtilityPresentation.merge("", socialNetworks?.facebook),
            instagram: UtilityPresentation.merge("", socialNetworks?.instagram),
            twitter: UtilityPresentation.merge("", socialNetworks?.twitter),
            linkedIn: UtilityPresentation.merge("", socialNetworks?.linkedIn),
            youTube: UtilityPresentation.merge("", socialNetworks?.youTube)
        };
    }

    private static merge<T>(defaultValue: T, value?: T): T {
        return value !== undefined ? value : defaultValue
    }
}