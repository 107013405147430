import React, { FormEvent, MouseEvent, useEffect, useState } from "react";
import {
  Card,
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CustomDialog from "../../../components/CustomDialog";
import Loader from "../../../components/Loader";
import { RootStore } from "../../../redux/Store";
import {
  EditUserReset,
  EditUserChangeInputs,
  EditUser,
  GetUser,
  EditUserUtility,
} from "../../../redux/user/UserActions";
import ErrorMessage from "../../../components/ErrorMessage";
import CustomSelect from "../../../components/CustomSelect";
import { GetUtilities } from "../../../redux/utilityList/UtilityListActions";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(2),
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  title: {
    marginTop: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  textField: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2CDEA8",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#2CDEA8",
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#2CDEA8",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#2CDEA8",
    },
    marginBottom: '25px'
  },
  buttonGrid: {
    margin: theme.spacing(3, 0, 3),
  },
  submit: {
    color: "#ffffff",
    backgroundColor: "#2CDEA8",
    "&:hover": {
      backgroundColor: "#2CDEA8",
    },
  },
  loader: {
    top: "20%",
    left: "10%",
    position: "absolute",
    height: "80%",
    width: "80%"
  },
  cancel: {
    color: "#ffffff",
    backgroundColor: "#A4A4A4",
    "&:hover": {
      backgroundColor: "#A4A4A4",
    },
  },
}));

export default function User() {
  const [openSave, setOpenSave] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [edited, setEdited] = useState(false);


  let user = useSelector((state: RootStore) => state.userReducer);
  let utilities = useSelector((state: RootStore) => state.utilityListReducer)

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const routeParts = location.pathname.split('/');
    const id = routeParts[routeParts.length - 1];
    dispatch(GetUser(id))
    dispatch(GetUtilities())
    return function cleanUp() {
      dispatch(EditUserReset())
    }
  }, [location, dispatch]);


  useEffect(() => {
    if (user.success) {
      history.goBack()
      dispatch(EditUserReset())
    }
  }, [user.success, dispatch, history]);


  const onChangeInputs = (field: string, value: string) => {
    setEdited(true);
    dispatch(EditUserChangeInputs({ field, value }));
  };

  const openCancelDialog = (event: MouseEvent<HTMLButtonElement>) => {
    if (edited) {
      setOpenCancel(true);
    } else {
      history.goBack();
      dispatch(EditUserReset());
    }
  };

  const openSaveDialog = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOpenSave(true);
  };

  const handleCloseDialog = () => {
    if (openCancel) setOpenCancel(false);
    if (openSave) setOpenSave(false);
  };

  const handleDiscardDialog = () => {
    history.goBack();
    dispatch(EditUserReset());
    setOpenCancel(false);
  };

  const handleSaveDialog = () => {
    dispatch(EditUser());
    setOpenSave(false);
  };

  const onUtilityNameChange = (value?: string) => {
    if (value) {
      setEdited(true);
      dispatch(EditUserUtility(value))
    }
  }


  const getUtilityNames = () => {
    return utilities.utilityList.map((utility, index) => ({
      name: utility.name + ' (' + utility.shortName + ')',
      value: utility.shortName,
    }))
  }

  let utilityNames = getUtilityNames()

  const classes = useStyles();

  return (
    <Card className={classes.main} raised>
      {user.loading ? <Container maxWidth={false} disableGutters={true} className={classes.loader}><Loader /></Container> : ""}
      {user.error !== "" ? <ErrorMessage message={user.error} /> : ""}
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <form className={classes.form} noValidate onSubmit={openSaveDialog}>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-start"
          >
            <Grid item xs={8} md={3}>
              <Typography
                component={"h2"}
                variant={"h5"}
                className={classes.title}
              >
                Basic info
              </Typography>
              <TextField
                margin="normal"
                className={classes.textField}
                variant="outlined"
                id="userName"
                label="Name"
                name="userName"
                fullWidth
                disabled
                value={user.name}
              />
              <TextField
                margin="normal"
                className={classes.textField}
                variant="outlined"
                id="userEmail"
                label="Email"
                name="userEmail"
                fullWidth
                disabled
                value={user.email}
              />
              <TextField
                margin="normal"
                className={classes.textField}
                variant="outlined"
                id="userLanguage"
                label="Language"
                name="userLanguage"
                fullWidth
                disabled
                value={user.language}
              />
              <TextField
                margin="normal"
                className={classes.textField}
                variant="outlined"
                id="userZipCode"
                label="Zip Code"
                name="userZipCode"
                fullWidth
                value={user.zipCode}
                onChange={(event) =>
                  onChangeInputs("zipCode", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={8} md={3}>
              <Typography
                component={"h2"}
                variant={"h5"}
                className={classes.title}
              >
                Utility
              </Typography>
              <CustomSelect
                options={utilityNames}
                onSelectChange={onUtilityNameChange}
                selected={user.utilityId}
              />
              <Divider variant="middle" className={classes.divider} />
              <Typography
                component={"h2"}
                variant={"h5"}
                className={classes.title}
              >
                Notification Information
              </Typography>
              <FormControl component="fieldset" fullWidth disabled>
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="userUtility"
                    control={
                      <Checkbox
                        color="primary"
                        checked={user.notificationUtility}
                      />
                    }
                    label="Utility"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="userTips"
                    control={
                      <Checkbox
                        color="primary"
                        checked={user.notificationTips}
                      />
                    }
                    label="Tips"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="userProgress"
                    control={
                      <Checkbox
                        color="primary"
                        checked={user.notificationProgress}
                      />
                    }
                    label="Progress"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={8} md={3}>
              <Typography
                component={"h2"}
                variant={"h5"}
                className={classes.title}
              >
                Accepted Terms
              </Typography>
              <FormControl component="fieldset" fullWidth disabled>
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="userSharedData"
                    control={
                      <Checkbox color="primary" checked={user.sharedData} />
                    }
                    label="Shared Data"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="userTermsOfConditions"
                    control={
                      <Checkbox
                        color="primary"
                        checked={user.termsOfConditions}
                      />
                    }
                    label="Terms Of Conditions"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
              <Divider variant="middle" className={classes.divider} />
              <Typography
                component={"h2"}
                variant={"h5"}
                className={classes.title}
              >
                Privacy Information
              </Typography>
              <FormControl component="fieldset" fullWidth disabled>
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="userCrashlytics"
                    control={
                      <Checkbox
                        color="primary"
                        checked={user.privacyCrashlytics}
                      />
                    }
                    label="Crashlytics"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="userPerformance"
                    control={
                      <Checkbox
                        color="primary"
                        checked={user.privacyPerformance}
                      />
                    }
                    label="Performance"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="userPromotion"
                    control={
                      <Checkbox
                        color="primary"
                        checked={user.privacyPromotion}
                      />
                    }
                    label="Promotion"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
              <Divider variant="middle" className={classes.divider} />
              <Typography
                component={"h2"}
                variant={"h5"}
                className={classes.title}
              >
                User Facts
              </Typography>
              <FormControl component="fieldset" fullWidth disabled>
                <FormGroup aria-label="position">
                  <FormControlLabel
                    value="userBlinker"
                    control={
                      <Checkbox color="primary" checked={user.hasBlinker} />
                    }
                    label="Blinker"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Divider variant="middle" className={classes.divider} />
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            alignContent="center"
            spacing={1}
            className={classes.buttonGrid}
          >
            <Grid item xs={2} md={5}></Grid>
            <Grid item xs={8} md={2}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Button
                    className={classes.cancel}
                    type="button"
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={openCancelDialog}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    className={classes.submit}
                    type="submit"
                    size="large"
                    variant="contained"
                    fullWidth
                    color="primary"
                    disabled={!edited}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} md={5}></Grid>
          </Grid>
        </form>
      </Grid>
      <CustomDialog
        open={openSave}
        onClose={handleCloseDialog}
        title="Save changes?"
        contentText="This action will result in updating the selected user."
        actions={[
          {
            text: "Cancel",
            click: handleCloseDialog,
            primary: false,
          },
          {
            text: "Save",
            click: handleSaveDialog,
            primary: true,
          },
        ]}
      />
      <CustomDialog
        open={openCancel}
        onClose={handleCloseDialog}
        title="Discard changes?"
        contentText="All changes made to the user will be discarded."
        actions={[
          {
            text: "Cancel",
            click: handleCloseDialog,
            primary: false,
          },
          {
            text: "Discard",
            click: handleDiscardDialog,
            primary: true,
          },
        ]}
      />
    </Card>
  );
}
