import ICheckAuthGateway from "../domain/modules/auth/gateway/ICheckAuthGateway";
import ISignInGateway from "../domain/modules/auth/gateway/ISignInGateway";
import ISignOutGateway from "../domain/modules/auth/gateway/ISignOutGateway";
import IEditUserGateway from "../domain/modules/user/gateway/IEditUserGateway";
import IAddMappingGateway from "../domain/modules/mapping/gateway/IAddMappingGateway";
import IDeleteMappingGateway from "../domain/modules/mapping/gateway/IDeleteMappingGateway";
import IEditMappingGateway from "../domain/modules/mapping/gateway/IEditMappingGateway";
import IGetMappingsGateway from "../domain/modules/mapping/gateway/IGetMappingsGateway";
import IEditStandardProfileGateway from "../domain/modules/standardProfile/gateway/IEditStandardProfileGateway";
import IStandardProfileGateway from "../domain/modules/standardProfile/gateway/IStandardProfileGateway";
import IGetUsersGateway from "../domain/modules/user/gateway/IGetUsersGateway";
import IEditUtilityGateway from "../domain/modules/utility/gateway/IEditUtilityGateway";
import IGetUtilityListGateway from "../domain/modules/utility/gateway/IGetUtilityListGateway";
import RepositoryFactory from "./RepositoryFactory";
import { IRecalculateStandardProfilesGateway } from "../domain/modules/mapping/gateway/IRecalculateStandardProfilesGateway";
import IGetUserGateway from "../domain/modules/user/gateway/IGetUserGateway";
import IGetUtilityLogosGateway from "../domain/modules/utility/gateway/IGetUtilityLogosGateway";
import IGetUtilityGateway from "../domain/modules/utility/gateway/IGetUtilityGateway";


export default class GatewayFactory {
    constructor(private repositoryFactory: RepositoryFactory) { }

    getSignInGateway(): ISignInGateway {
        return this.repositoryFactory.getTokenRepository();
    }

    getCheckAuthGateway(): ICheckAuthGateway {
        return this.repositoryFactory.getTokenRepository();
    }

    getSignOutGateway(): ISignOutGateway {
        return this.repositoryFactory.getTokenRepository();
    }

    getEditUserGateway(): IEditUserGateway {
        return this.repositoryFactory.getUserRepository();
    }

    getGetUsersGateway(): IGetUsersGateway {
        return this.repositoryFactory.getUserRepository();
    }

    getGetUserGateway(): IGetUserGateway {
        return this.repositoryFactory.getUserRepository();
    }

    getGetStandardProfilesGateway(): IStandardProfileGateway {
        return this.repositoryFactory.getStandardProfileRepository();
    }

    getGetUtilityListGateway(): IGetUtilityListGateway {
        return this.repositoryFactory.getUtilityRepository();
    }

    getUtilityGateway(): IGetUtilityGateway {
        return this.repositoryFactory.getUtilityRepository();
    }

    getEditUtilityGateway(): IEditUtilityGateway {
        return this.repositoryFactory.getUtilityRepository();
    }

    getGetUtilityLogosGateway(): IGetUtilityLogosGateway {
        return this.repositoryFactory.getUtilityRepository();
    }

    getGetMappingsGateway(): IGetMappingsGateway {
        return this.repositoryFactory.getGetMappingsGateway();
    }

    getEditStandardProfileGateway(): IEditStandardProfileGateway {
        return this.repositoryFactory.getStandardProfileRepository();
    }

    getDeleteMappingGateway(): IDeleteMappingGateway {
        return this.repositoryFactory.getMappingRepository();
    }

    getAddMappingGateway(): IAddMappingGateway {
        return this.repositoryFactory.getMappingGateway();
    }

    getEditMappingGateway(): IEditMappingGateway {
        return this.repositoryFactory.getMappingGateway();
    }

    getRecalculateStandardProfilesGateway(): IRecalculateStandardProfilesGateway {
        return this.repositoryFactory.getMappingRepository();
    }
}
