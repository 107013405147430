import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { AppBar, Container, Tab, Tabs } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  navContainer: {
    position: "relative",
    width: "25vw",
    right: 0,
    top: -15
  },
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(-2)
  },
  appBar: {
    boxShadow: 'none',
    margin: theme.spacing(0, 0, 2)
  },
  tab: {
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    boxShadow: "none"
  },
  indicator: {
    background: theme.palette.primary.main,
  }
}));

const tabLabels = ["Profiles", "Mappings"];

export default function StandardProfiles() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const history = useHistory();

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    history.push("profiles/");
  }, [history]);

  useEffect(() => {
    if (value === 1) {
      history.replace("/app/standard-profiles/mappings/");
    } else history.replace("/app/standard-profiles/profiles/");
  }, [value, history]);

  return (
    <Container className={classes.navContainer} maxWidth={false} disableGutters={true}>
      <AppBar className={classes.appBar} position="relative" color="transparent">
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          classes={{ indicator: classes.indicator }}
          className={classes.tab}
          centered
        >
          {tabLabels.map((el, i) => (
            <Tab label={el} key={i} />
          ))}
        </Tabs>
      </AppBar>
    </Container>
  );
}
