import { FormEvent, MouseEvent, useState } from "react";
import {
  Backdrop,
  Button,
  Card,
  Container,
  Divider,
  Fade,
  Grid,
  makeStyles,
  Modal,
  TextField,
  Typography
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootStore } from "../../../redux/Store";
import {
  EditUtilityInputs,
  EditUtilityReset,
  EditUtility,
  SelectLogo,
  GetUtility,
  ResetSelectedLogo
} from "../../../redux/utility/UtilityActions";
import CustomDialog from "../../../components/CustomDialog";
import AppModal from "../../../components/AppModal";
import placeholderLight from "../../../../static/placeholder_light.png";
import placeholderDark from "../../../../static/placeholder_dark.png";
import { useEffect } from "react";
import UtilityFormDragDrop from "../utilityFormModal/utilityFormDragDrop";
import UtilityFormAllPictures from "../utilityFormModal/UtilityFormAllPictures";
import ErrorMessage from "../../../components/ErrorMessage";
import Loader from "../../../components/Loader";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  main: {
    margin: theme.spacing(2, 0, 0)
  },
  form: {
    width: "100%",
    margin: theme.spacing(1, 0, 0),
  },
  loader: {
    top: "20%",
    left: "10%",
    position: "absolute",
    height: "80%",
    width: "80%"
  },
  title: {
    margin: theme.spacing(2, 0, 0),
  },
  label: {
    margin: theme.spacing(1, 0, 1),
  },
  textField: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[400]
    },
    "&:hover .MuiInputLabel-outlined": {
      color: theme.palette.grey[400]
    }
  },
  divider: {
    margin: theme.spacing(2, 0, 1),
  },
  dividerBottom: {
    margin: theme.spacing(0, 0, 2)
  },
  submit: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonGrid: {
    margin: theme.spacing(2, 0, 3),
  },
  imageContainer: {
    margin: theme.spacing(2, 0, 0),
    width: "100%",
    paddingBottom: "100%",
    position: "relative",
    borderRadius: "2%",
    border: `0.01px solid ${theme.palette.text.primary}`,
    overflow: "hidden",
    backgroundColor: theme.palette.text.primary,
    "&:hover": {
      cursor: "pointer",
      "& $image": {
        opacity: 0.9
      },
      "& $spanImage": {
        opacity: 1
      }
    }
  },
  image: {
    margin: "auto",
    top: "50%",
    left: "50%",
    width: "100%",
    position: "absolute",
    backgroundSize: "auto",
    transition: ".5s ease",
    opacity: 1,
    background: theme.palette.grey[300],
    backfaceVisibility: "hidden",
    display: "block",
    transform: "translate(-50%, -50%)"
  },
  spanImage: {
    position: "absolute",
    left: 0,
    bottom: 0,
    opacity: 0,
    textAlign: "center",
    width: "100%",
    background: "rgba(0, 0, 0, 0.4)",
    padding: "10% 0",
    color: theme.palette.secondary.main
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

export default function Utility() {
  const [openSave, setOpenSave] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openEditLogo, setOpenEditLogo] = useState(false);
  const [edited, setEdited] = useState(false);
  const [logoLight, setLogoLight] = useState(placeholderLight);
  const [logoDark, setLogoDark] = useState(placeholderDark);
  const state = useSelector((state: RootStore) => state.utilityReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const openCancelDialog = (_event: MouseEvent<HTMLButtonElement>) => {
    if (edited) {
      setOpenCancel(true);
    } else {
      dispatch(EditUtilityReset());
      history.goBack();
    }
  };

  function openSaveDialog(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setOpenSave(true);
  };

  const openEditLogoDialog = (selectedImage: string, type: string) => {
    dispatch(SelectLogo(type, selectedImage));
    setOpenEditLogo(true);
  };

  const handleCloseDialog = () => {
    if (openCancel) setOpenCancel(false);
    if (openSave) setOpenSave(false);
  }

  const handleCloseEditLogo = () => {
    dispatch(ResetSelectedLogo());
    setOpenEditLogo(false);
  }

  const handleDiscardDialog = () => {
    history.goBack();
    dispatch(EditUtilityReset());
    setOpenCancel(false);
  }

  const handleSaveDialog = () => {
    setOpenSave(false);
    dispatch(EditUtility());
  }

  const onChangeInputs = (field: string, value: string) => {
    setEdited(true);
    dispatch(EditUtilityInputs({ field, value }));
  }

  useEffect(() => {
    dispatch(EditUtilityReset());
    const routeParts = location.pathname.split('/');
    const id = parseInt(routeParts[routeParts.length - 1]);

    dispatch(GetUtility(id));
  }, [location, dispatch]);

  useEffect(() => {
    if (state.logoLight !== "" && state.logoLight !== undefined) {
      setLogoLight(state.logoLight);
    } else {
      setLogoLight(placeholderLight);
    }

    if (state.logoDark !== "" && state.logoDark !== undefined) {
      setLogoDark(state.logoDark);
    } else {
      setLogoDark(placeholderDark);
    }
  }, [state.logoLight, state.logoDark]);

  useEffect(() => {
    if (state.success) {
      history.goBack();
      dispatch(EditUtilityReset());
    }
  }, [state.success, dispatch, history]);

  const classes = useStyles();

  return (
    <Card className={classes.main} raised>
      {state.loading ? <Container maxWidth={false} disableGutters={true} className={classes.loader}><Loader /></Container> : ""}
      {state.error !== "" ? <ErrorMessage message={state.error} /> : ""}
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <form className={classes.form} noValidate onSubmit={openSaveDialog}>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-start"
          >
            <Grid item xs={8} md={3}>
              <Typography
                component={"h2"}
                variant={"h5"}
                className={classes.title}
              >
                Basic info
              </Typography>
              <TextField
                id="utilityName"
                label="Name"
                name="utilityName"
                value={state.name}
                variant= "outlined"
                margin="normal"
                onChange={(event) =>
                  onChangeInputs("name", event.target.value)
                }
              />
              <TextField
                className={classes.textField}
                id="utilityShortName"
                label="Short Name"
                name="utilityShortName"
                value={state.shortName}
                variant= "outlined"
                margin="normal"
                disabled
              />
              <TextField
                id="utilityDescription"
                label="Description"
                name="utilityDescription"
                value={state.description}
                multiline
                variant= "outlined"
                margin="normal"
                rows={4}
                onChange={(event) =>
                  onChangeInputs("description", event.target.value)
                }
              />
              <Divider variant="middle" className={classes.divider} />
              <Typography
                component={"h2"}
                variant={"h5"}
                className={classes.title}
              >
                Logos
              </Typography>
              <Grid
                container
                spacing={3}
                direction="row"
              >
                <Grid item xs={8} md={5}>
                  <Grid item xs={12} className={classes.imageContainer} onClick={() => openEditLogoDialog(logoLight, "logoLight")}>
                    <img className={classes.image} alt={"logoLight"} src={logoLight} />
                    <span className={classes.spanImage}>Edit</span>
                  </Grid>
                  <Typography
                    component={"h6"}
                    variant={"subtitle1"}
                    className={classes.label}
                    align="center"
                  >
                    Light
                  </Typography>
                </Grid>
                <Grid item xs={8} md={5}>
                  <Grid item xs={12} className={classes.imageContainer} onClick={() => openEditLogoDialog(logoDark, "logoDark")}>
                    <img className={classes.image} alt={"logoDark"} src={logoDark}></img>
                    <span className={classes.spanImage}>Edit</span>
                  </Grid>
                  <Typography
                    component={"h6"}
                    variant={"subtitle1"}
                    className={classes.label}
                    align="center"
                  >
                    Dark
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8} md={3}>
              <Typography
                component={"h2"}
                variant={"h5"}
                className={classes.title}
              >
                Contacts
              </Typography>
              <TextField
                id="utilityAddress"
                label="Address"
                name="utilityAddress"
                value={state.address}
                variant= "outlined"
                margin="normal"
                onChange={(event) =>
                  onChangeInputs("address", event.target.value)
                }
              />
              <TextField
                id="utilityEmail"
                label="Email"
                name="utilityEmail"
                value={state.email}
                variant= "outlined"
                margin="normal"
                onChange={(event) =>
                  onChangeInputs("email", event.target.value)
                }
              />
              <TextField
                id="utilityPhone"
                label="Phone Number"
                name="utilityPhone"
                value={state.phone}
                variant= "outlined"
                margin="normal"
                onChange={(event) =>
                  onChangeInputs("phone", event.target.value)
                }
              />
              <TextField
                id="utilityWebsite"
                label="Website"
                name="utilityWebsite"
                value={state.website}
                variant= "outlined"
                margin="normal"
                onChange={(event) =>
                  onChangeInputs("website", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={8} md={3}>
              <Typography
                component={"h2"}
                variant={"h5"}
                className={classes.title}
              >
                Social Networks
              </Typography>
              <TextField
                id="utilityFacebook"
                label="Facebook"
                name="utilityFacebook"
                value={state.facebook}
                variant= "outlined"
                margin="normal"
                onChange={(event) =>
                  onChangeInputs("facebook", event.target.value)
                }
              />
              <TextField
                id="utilityInstagram"
                label="Instagram"
                name="utilityInstagram"
                value={state.instagram}
                variant= "outlined"
                margin="normal"
                onChange={(event) =>
                  onChangeInputs("instagram", event.target.value)
                }
              />
              <TextField
                id="utilityTwitter"
                label="Twitter"
                name="utilityTwitter"
                value={state.twitter}
                variant= "outlined"
                margin="normal"
                onChange={(event) =>
                  onChangeInputs("twitter", event.target.value)
                }
              />
              <TextField
                id="utilityLinkedIn"
                label="LinkedIn"
                name="utilityLinkedIn"
                value={state.linkedIn}
                variant= "outlined"
                margin="normal"
                onChange={(event) =>
                  onChangeInputs("linkedIn", event.target.value)
                }
              />
              <TextField
                id="utilityYouTube"
                label="YouTube"
                name="utilityYouTube"
                value={state.youTube}
                variant= "outlined"
                margin="normal"
                onChange={(event) =>
                  onChangeInputs("youTube", event.target.value)
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            alignContent="center"
            spacing={1}
            className={classes.buttonGrid}
          >
            <Grid item xs={1} md={1} />
            <Grid item xs={10} md={10}>
              <Divider variant="middle" className={classes.dividerBottom} />
            </Grid>
            <Grid item xs={1} md={1} />
            <Grid item xs={1} md={5} />
            <Grid item xs={10} md={2}>
              <Grid
                container
                spacing={3}>
                <Grid item xs={12} md={6}>
                  <Button
                    type="button"
                    size="large"
                    fullWidth
                    onClick={openCancelDialog}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    className={classes.submit}
                    type="submit"
                    size="large"
                    disabled={!edited}
                    fullWidth
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} md={5} />
          </Grid>
        </form>
      </Grid>
      <CustomDialog
        open={openSave}
        onClose={handleCloseDialog}
        title="Save changes?"
        contentText="This action will result in updating the selected utility."
        actions={[
          {
            text: "Cancel",
            click: handleCloseDialog,
            primary: false
          },
          {
            text: "Save",
            click: handleSaveDialog,
            primary: true
          }
        ]}
      />
      <CustomDialog
        open={openCancel}
        onClose={handleCloseDialog}
        title="Discard changes?"
        contentText="All changes made to the utility will be discarded."
        actions={[
          {
            text: "Cancel",
            click: handleCloseDialog,
            primary: false
          },
          {
            text: "Discard",
            click: handleDiscardDialog,
            primary: true
          }
        ]}
      />
      <Modal
        open={openEditLogo}
        onClose={handleCloseEditLogo}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openEditLogo}>
          <AppModal
            closeButton
            handleCloseEditLogo={handleCloseEditLogo}
            tabLabels={["Upload logo", "All logos"]}
            tabs={[<UtilityFormDragDrop handleCloseEditLogo={handleCloseEditLogo} edit={setEdited} />, <UtilityFormAllPictures handleCloseEditLogo={handleCloseEditLogo} edit={setEdited} />]}
          />
        </Fade>
      </Modal>
    </Card>
  );
}
