import { Dispatch } from "redux";
import { dependencyContainer } from "../../..";
import { GetUtilityListInteractor } from "../../../domain/modules/utility/useCase/GetUtilityListUseCase";
import GetUtilityListPresenter from "../../presenter/utility/GetUtilityListPresenter";
import ReduxDispatch from "../../presenter/ReduxDispatch";
import UtilityPresentation from "../../presenter/utility/presentation/UtilityPresentation";

export const GetUtilities = () => async (dispatch: Dispatch) => {
    new GetUtilityListInteractor(new GetUtilityListPresenter(new ReduxDispatch(dispatch), new UtilityPresentation()),
        dependencyContainer.dependency.gatewayFactory.getGetUtilityListGateway())
        .getUtilityList()
}