import { Typography } from "@material-ui/core";
import React from "react";

export default function Versioning() {

    return(
        <Typography>
            Versioning v1.0.0
        </Typography>
    )
}
