import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { CheckAuth } from "../../redux/base/BaseActions";

import { RootStore } from "../../redux/Store";

const BASE_AUTHORIZED_ROUTE = "/app";

export default function BasePage() {
  const { ready, signedIn } = useSelector((state: RootStore) => ({
    signedIn: state.baseReducer.signedIn,
    ready: state.baseReducer.ready,
  }));
  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(CheckAuth());
  }, [dispatch]);

  React.useEffect(() => {
    if (ready) {
      if (signedIn === true) {
        if (!history.location.pathname.startsWith(BASE_AUTHORIZED_ROUTE))
          history.push(`${BASE_AUTHORIZED_ROUTE}/users/`);
        // history.push(`${BASE_AUTHORIZED_ROUTE}/home`);
      } else {
        if (
          history.location.pathname.startsWith(BASE_AUTHORIZED_ROUTE) ||
          history.location.pathname === "/"
        )
          history.push("/sign-in");
      }
    }
  }, [history, signedIn, ready]);

  return <div>{/* THIS IS NOT PLACE FOR ANY UI*/}</div>;
}
