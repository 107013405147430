import MaterialTable from "material-table";
import React from "react";
import { Chip, Container, Grid, makeStyles } from "@material-ui/core";
import { UserViewModel } from "../../presenter/user/viewModel/UserViewModel";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

interface UsersTableProps {
  userList: UserViewModel[];
  utilityLookup: object;
  handleEdit: (
    event: React.MouseEvent<HTMLButtonElement>,
    rowData: UserViewModel | UserViewModel[]
  ) => void;
  handleDelete: (
    event: React.MouseEvent<HTMLButtonElement>,
    rowData: UserViewModel | UserViewModel[]
  ) => void;
}
const booleanLookup = { true: "yes", false: "none" };

const useStyles = makeStyles(() => ({
  chipYes: {
    color: "#2CDEA8",
  },
  chipNo: {
    color: "#FF7F7F",
  },
  mail: {
    textDecoration: "none",
    color: "#000000"
  },
  id: {
    cursor: "pointer"
  },
  tableHeader: {
    color: 'black'
  }
}));

export default function UsersTable(props: UsersTableProps) {
  const classes = useStyles();
  const { userList, handleEdit, utilityLookup } = props;
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="stretch"
    >
      <MaterialTable
        columns={[
          {
            title: "User ID",
            field: "id",
            filterPlaceholder: "Filter by user ID",
            render: (rowData: UserViewModel) => <Container maxWidth={false} disableGutters={true} className={classes.id} onClick={(e: any) => handleEdit(e, rowData)}>{rowData.id}</Container>
          },
          {
            title: "Name",
            field: "name",
            filterPlaceholder: "Filter by name"
          },
          {
            title: "Email",
            field: "email",
            filterPlaceholder: "Filter by email",
            render: (rowData: UserViewModel) =>
              <a href={`mailto:${rowData.email}`} className={classes.mail}>{rowData.email}</a>
          },
          {
            title: "Utility",
            field: "utilityId",
            lookup: utilityLookup,
            filterPlaceholder: "Filter by utility",
            // render: (rowData: UserViewModel) => <div className={classes.id} onClick={(e: any) => handleEditUtility(e, rowData)}>{rowData.utilityId}</div>
          },
          {
            title: "Blinker",
            field: "hasBlinker",
            filterPlaceholder: "Filter by blinker",
            lookup: booleanLookup,
            render: (rowData: UserViewModel) => {
              return rowData.hasBlinker === true ? (
                <Chip
                  label="yes"
                  className={classes.chipYes}
                  variant="outlined"
                />
              ) : (
                <Chip label="none" className={classes.chipNo} variant="outlined" />
              );
            },
          },
        ]}
        actions={[
          {
            icon: "edit",
            tooltip: "Edit User",
            onClick: handleEdit,
          },
          {
            icon: () => <DeleteOutlineIcon />,
            tooltip: "Delete User",
            disabled: true,
            onClick: () => { },
          },
        ]}
        data={userList}
        options={{
          filtering: true,
          search: false,
          showTitle: false,
          pageSize: 10,
          pageSizeOptions: [10, 15, 20, 25],
          emptyRowsWhenPaging: false,
          toolbar: false,
          actionsColumnIndex: -1
        }}
      />
    </Grid>
  );
}
